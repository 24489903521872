.App {
  text-align: left;
}

.App-header {
  background-color: #282c34;
  min-height: 0vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* Set a background image by replacing the URL below */
.App-body {
  /*background: url("/assets/background.jpg") no-repeat center center fixed; */
  /*background-image: url("./assets/background.jpg") no-repeat center center fixed;  */
  /*background-color: #282c34; */
  /*-webkit-background-size: cover; */
  /*-moz-background-size: cover; */
  /*background-size: cover; */
  /*-o-background-size: cover; */
  padding: 20px 0;
}


.App-footer {
  padding: 0px 0;
}


/*On Hover Color Change*/
.pagelinks {
  color: grey;
  text-decoration: none;
}

.pagelinks:hover {
  color: #99ccff;
  text-decoration: none;
}

section {
  padding: 10px 0;
}